<template>
  <div class="bg-white-900 mb-14 mt-2 terms" style="padding: 0">
    <div class="container mx-auto h-full content">
      <h1 class="text-center font-medium text-black-900 text-5xl mb-5 mt-3">
        Boodata.sg End-User Licence Agreement
      </h1>
      <p class="font-bold">
        Please read the following carefully before using this Database or
        accessing or downloading any Book Data from this Database:
      </p>

      <ol class="list1">
        <li class="mb-3">
          The
          <span class="font-bold">SINGAPORE BOOK PUBLISHERS ASSOCIATION</span>
          ("<span class="font-bold">SBPA</span>") is a society registered under
          the Societies Act 1966 (UEN S68SS0043D), with a registered address at
          9 Jurong Town Hall Road, #02-02 Jurong Town Hall, Singapore 609431;
        </li>
        <li class="mb-3">
          This is licence agreement ("<span class="font-bold">License</span>")
          is a legal agreement between the SBPA and the organisation for which
          an account on the Database will be created (the "<span
            class="font-bold"
            >Subscriber</span
          >"). SBPA licences the use of the Database and Book Data on the terms
          of this Licence.
        </li>
        <li class="mb-3">
          By clicking on the "Accept" button below, you agree to the terms of
          this Licence on behalf of the Subscriber. You confirm that you are
          authorised to bind the Subscriber to the terms of this Licence.
        </li>
        <li class="mb-3">
          If the Subscriber does not agree to the terms of this Licence, you
          must not click on the “Accept” button, and you/ the Subscriber must
          not use this Database or access or download any Book Data from this
          Database.
        </li>
      </ol>
      <p class="font-bold">
        Please save a copy of this Licence for future reference.
      </p>

      <ol class="list2">
        <li>
          <span class="font-bold">DEFINITIONS</span>
          <ol class="list3 mt-3">
            <li class="mb-3">
              <div>
                <p>
                  In this Licence, the following words and phrases shall have
                  the meanings set out below:
                </p>
                <p>
                  "<span class="font-bold">Book Data</span>" refers to the
                  bibliographic information of any publication that is
                  accessible by the Subscriber on the Database, including but
                  not limited to the International Standard Book Number, author
                  name(s), publisher name(s), page count, publication titles,
                  publication synopses, physical measurements of any hardcopy
                  versions of the publication, and cover images.
                </p>
                <p>
                  "<span class="font-bold">Database</span>" refers to
                  <a href="https://bookdata.sg">https://bookdata.sg</a>
                </p>
              </div>
            </li>
            <li class="mb-3">
              Any reference to a statutory provision shall include such
              provision and any regulations made in pursuance thereof as from
              time to time modified or re‑enacted whether before or after the
              date of this Licence so far as such modification or re‑enactment
              applies or is capable of applying to any transactions contemplated
              by or entered into in pursuance of this Licence and (so far as
              liability thereunder may exist or can arise) shall include also
              any past statutory provisions or regulations (as from time to time
              modified or re‑enacted) which such provisions or regulations have
              directly or indirectly replaced.
            </li>
            <li class="mb-3">
              The headings in this Licence are for convenience only and shall
              not affect the interpretation of the clauses thereunder.
            </li>
            <li class="mb-3">
              Unless the context otherwise requires, references to the singular
              number shall include references to the plural number and vice
              versa.
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">GRANT OF LICENCE</p>
          <p>
            In consideration of payment by the Subscriber of the agreed fees as
            set out in schedule of fees, which has been sent to the Subscriber,
            and the Subscriber’s agreement to the terms of this Licence, SBPA
            grants the Subscriber a non-exclusive and non-transferable licence
            to use the Database and Book Data on the terms of this Licence.
          </p>
        </li>
        <li>
          <span class="font-bold">DURATION OF LICENCE</span>
          <ol class="list3 mt-3">
            <li>
              <p>
                This Licence is effective from the date on which the Subscriber
                indicates acceptance in accordance with Recital (B) and shall
                remain in effect for a minimum period of one (1) year ("<span
                  class="font-bold"
                  >Initial Term</span
                >") unless terminated in accordance with this Licence.
              </p>
            </li>
            <li>
              <p>
                At the expiration of the Initial Term, this Licence will
                automatically renew for successive periods of one (1) year each
                (each such period hereinafter known as an "<span
                  class="font-bold"
                  >Additional Term</span
                >") unless terminated in accordance with this Licence.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span class="font-bold"
            >RESTRICTIONS ON USE OF DATABASE AND BOOK DATA</span
          >
          <ol class="list3 mt-3">
            <li>
              <p>
                The Database and Book Data are only to be used by the Subscriber
                or its employees and only as expressly set out in this Licence.
                The Subscriber shall procure that its employees comply with this
                Licence.
              </p>
            </li>
            <li>
              <p>
                Subject to the conditions in Clauses 4.3 and 4.4, the Subscriber
                and its employees may only use the Database and the Book Data
                for the following purposes:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    Populating the Subscriber’s website and/or point of sale
                    system database with bibliographic information in relation
                    to books which are offered for sale or loan to consumers by
                    the Subscriber;
                  </p>
                </li>
                <li>
                  <p>
                    Advertising, marketing or promotional purposes in relation
                    to books which are offered for sale or loan to consumers by
                    the Subscriber.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>The Subscriber and its employees shall not:</p>
              <ol class="list4">
                <li>
                  <p>
                    Disclose any Book Data or any other Database content to any
                    third party, except in the course of the permitted uses in
                    Clause 4.2;
                  </p>
                </li>
                <li>
                  <p>
                    Permit any Book Data or other Database content to be
                    downloaded or obtained in bulk from the Subscriber’s website
                    or any other physical or online location in any format;
                  </p>
                </li>
                <li>
                  <p>Modify any Book Data or other Database content.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                The Subscriber shall ensure that its use of the Database and the
                Book Data complies with all legal and statutory requirements,
                including but not limited to the Copyright Act 2021.
              </p>
            </li>
            <li>
              <p>
                The Subscriber shall take reasonable precautions to prevent
                unauthorised access, download or use of any Book Data or other
                content obtained by the Subscriber from the Database wherever it
                is stored, used or displayed.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <span class="font-bold"
            >DISCLAIMERS, EXCLUSION OF LIABILITY & INDEMNITY</span
          >
          <ol class="list3 mt-3">
            <li>
              <p>
                The Subscriber accepts that SBPA makes no warranty, guarantee or
                any representations as to the accuracy of the Book Data and
                other Database content. The Book Data and other Database content
                are obtained from third party sources, the accuracy of which has
                not been verified by SBPA.
              </p>
            </li>
            <li>
              <p>
                The Subscriber accepts that SBPA makes no warranty as to the
                ability to use, access or download from the Database. The
                Database may be suspended, discontinued or amended at any time
                at SBPA’s sole discretion or due to circumstances outside the
                control of SBPA.
              </p>
            </li>
            <li>
              <p>
                Accordingly, the Subscriber accepts that its use of the Database
                is at its own risk. SBPA shall not in any circumstances (except
                in the case of death or personal injury arising from SBPA’s
                negligence) be liable to the Subscriber for any direct or
                indirect or consequential losses arising under or in connection
                with the Licence, whether in contract, tort (including
                negligence), breach of statutory duty or otherwise.
              </p>
            </li>
            <li>
              <p>
                The Subscriber shall also fully and completely indemnify SBPA
                against any and all losses, expenses, costs and damages
                (including without limitation legal fees and disbursements)
                arising out of or in connection with this Licence, whether or
                not such losses, expenses, costs or damages (including legal
                fees) are due to it or to any third party.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">INTELLECTUAL PROPERTY RIGHTS</p>
          <p>
            The Subscriber acknowledges that all intellectual property rights in
            the Book Data and other Database content, including but not limited
            to the publication cover images, publication synopses, and any trade
            marks, belong to the SBPA and/or the relevant proprietors of the
            same (examples include but are not limited to publishers, authors
            and/or creators of the publications), and that such intellectual
            property rights are only licenced to the Subscriber only for the
            purposes set out in Clause 4. The Subscriber has no rights in, or
            to, the Book Data and other Database content other than the rights
            granted under this Licence.
          </p>
        </li>
        <li>
          <p class="font-bold">AMENDMENTS</p>
          <p>
            SBPA may amend this Licence at any time at its sole discretion and
            shall notify the Subscriber of the amended Licence by email to the
            email associated with the Subscriber’s account on the Database. By
            continuing to use the Database after deemed receipt of the aforesaid
            email notification, the Subscriber accepts the Licence as amended.
          </p>
        </li>
        <li>
          <span class="font-bold">TERMINATION</span>
          <ol class="list3 mt-3">
            <li>
              <p>
                During the Initial Term, the Subscriber may terminate the
                Licence at the expiry of the Initial Term (but no earlier) by
                giving not less than thirty (30) days’ written notice to SBPA
                that it wishes to terminate the Licence at the expiry of the
                Initial Term.
              </p>
            </li>
            <li>
              <p>
                During any Additional Term, the Subscriber may terminate the
                Licence at the expiry of the relevant Additional Term by giving
                not less than ninety (90) days’ written notice to SBPA that it
                wishes to terminate the Licence at the expiry of the relevant
                Additional Term.
              </p>
            </li>
            <li>
              <p>
                SBPA may terminate the Subscriber’s access to the Database at
                any time for any reason at its sole discretion, by giving
                written notice to the Subscriber by email to the email
                associated with the Subscriber’s account on the Database. Upon
                termination by SBPA:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    The Subscriber and its employees shall immediately cease all
                    use of the Database, all activities authorised by this
                    Licence, permanently delete or remove all Book Data and
                    other Database content from all computer equipment in their
                    possession, custody or control and destroy or return to SBPA
                    (at SBPA’s option) all physical copies of Book Data and
                    other Database content in the their possession, custody or
                    control;
                  </p>
                </li>
                <li>
                  <p>
                    The Subscriber is not entitled to a refund of any fees paid
                    to SBPA and any refund shall be at SBPA’s sole discretion.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <span class="font-bold">MISCELLANEOUS</span>
          <ol class="list3 mt-3">
            <li>
              <span class="font-bold"> Entire Agreement </span>
              <p>
                This Licence constitutes the entire agreement between SBPA and
                the Subscriber and supersedes and extinguishes any and all
                previous discussions, negotiations, correspondence, drafts,
                agreements, promises, assurances, warranties, representations
                and/or understandings between them, whether written or oral,
                relating to the subject matter of the Licence.
              </p>
            </li>
            <li>
              <span class="font-bold"> Waiver </span>
              <p>
                A waiver of any right or remedy under this Licence is only
                effective if it is given in writing and signed by the party
                waiving such right or remedy. Any such waiver shall apply only
                to the circumstances for which it is given and shall not be
                deemed a waiver of any subsequent breach or default. A failure
                or delay by a party to exercise any right or remedy under this
                Licence or by law shall not be deemed to constitute a waiver of
                that or any other right or remedy.
              </p>
            </li>
            <li>
              <span class="font-bold"> Notices </span>
              <p>
                Any notice given to a party under or in connection with this
                Licence shall be in writing and in the English language and
                shall be sent by email only. Notices shall be sent to SBPA at
                bookdata@singaporebookpublishers.sg. Notices shall be sent to
                the Subscriber at the email associated with the Subscriber’s
                account on the Database. Notices shall be deemed received at the
                time of transmission.
              </p>
            </li>
            <li>
              <span class="font-bold"> Severance </span>
              <p>
                If any term or part of a term of this Licence is or becomes
                invalid, illegal or unenforceable, it shall be deemed modified
                to the minimum extent necessary to make it valid, legal and
                enforceable. If such modification is not possible, the relevant
                term or part of a term shall be deemed deleted. Any modification
                to or deletion of a term or part of a term under this clause
                shall not affect the validity and enforceability of the rest of
                this Licence.
              </p>
            </li>
            <li>
              <span class="font-bold"> Exclusion of Third Party Rights </span>
              <p>
                The Contracts (Rights of Third Parties) Act (Chapter 53B) of
                Singapore (in the form as may be amended from time to time)
                shall not under any circumstances apply to this Licence and any
                person who is not a party to this Licence (whether or not such
                person shall be named, referred to, or otherwise identified, or
                form part of a class of persons so named, referred to or
                identified, in this Licence) shall have no right whatsoever
                under the Contracts (Rights of Third Parties) Act (Chapter 53B)
                of Singapore to enforce this Licence or any of its terms.
              </p>
            </li>
            <li>
              <span class="font-bold"> Governing Law and Jurisdiction </span>
              <p>
                This Licence and any dispute or claim arising out of or in
                connection with it or its subject matter or formation shall be
                governed by and construed in accordance with the laws of
                Singapore.
              </p>
            </li>
            <li>
              <span class="font-bold"> Dispute Resolution </span>
              <p>
                Any dispute arising out of or in connection with this Licence
                must first be submitted for mediation at the Singapore Mediation
                Centre (“SMC”) in accordance with SMC’s Mediation Procedure in
                force for the time being. Either party may submit a request to
                mediate to SMC upon which the other party will be bound to
                participate in the mediation within thirty (30) days thereof.
                Every party to the mediation must be represented by senior
                executive personnel, of at least the seniority of a Head of
                Department or its equivalent, with authority to negotiate and
                settle the dispute. Unless otherwise agreed by the parties, the
                Mediator(s) will be appointed by SMC. The mediation will take
                place in Singapore in the English language and the parties agree
                to be bound by any settlement agreement reached.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
